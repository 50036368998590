<template>
  <div class="history_boxs">
    <header class="history_head">
      <img src="@/assets/history/history.png" class="img_css" />
    </header>
    <main class="history_main">
      <div class="item_mian">
        <div class="main_title">
          <img src="@/assets/select_idx.png" class="item_icon" />
          <div class="item_title">隆福寺区块</div>
          <div class="item_title1">LONGFU BLOCK</div>
        </div>
        <div class="main_inner">
          <div class="item_boxs">
            <img src="@/assets/history/map_img.png" class="map_img" />
          </div>
          <div class="item_boxs text_info">
            &#12288;&#12288;隆福寺地区北至钱粮胡同、南至东四西大街、西至美术馆东街、东至东四北大街，占地面积约15.5万平方米。其中，功能区占地面积约6万平方米，建筑面积约18万平米，包括隆福大厦、隆福广场、长虹影院等；胡同风貌区为西侧和东北角的平房民居，占地面积约9.5万平方米。北侧毗邻张自忠路南历史文化保护区，南侧紧邻文化资源丰富的朝阜大街。
            <br/>&#12288;&#12288;隆福寺地区因隆福寺得名。隆福寺始建于明朝1452年，清至民国时期曾是京城最重要的庙会，并因其以书籍和高端艺术品交易为特色而被称为“文庙会”。
            解放后，隆福寺地区也是北京的核心商业区，隆福大厦和隆福寺街是八十年代改革开放后百姓购物的最主要场所。
          </div>
        </div>
      </div>
      <div class="item_mian">
        <div class="main_title">
          <img src="@/assets/select_idx.png" class="item_icon" />
          <div class="item_title">隆福寺历史</div>
          <div class="item_title1">HISTORY OF LONGFU</div>
        </div>
        <div class="main_inner">
            <img src="@/assets/history/history_map.png" class="history_map">
            <div class="item_boxs" style="width: auto">
                <div class="item_line">
                    <el-timeline>
                        <el-timeline-item
                            @mouseenter="enterChange(index)"
                            v-for="(activity, index) in activities"
                            :key="index"
                            :type="activity.type"
                            :size="activity.size"
                            :hollow="activity.hollow"
                            :timestamp="activity.timestamp"
                            :hide-timestamp="true"
                            :center="true"
                            :class="{'select_icon': selectIdx == index}"
                            >
                            <div class="item_line_text" :class="{'select_css': selectIdx == index}">{{ activity.content }}</div>
                        </el-timeline-item>
                    </el-timeline>
                </div>
            </div>
        </div>
      </div>
    </main>
    <footer></footer>
  </div>
</template>

<script  setup>
import { ref } from "vue";

const selectIdx = ref(2)
const activities = ref([
  {
    content: '1452明景泰帝下旨修建隆福寺，皇家寺庙，藩禅合一',
    timestamp: '',
    // icon: require("@/assets/history/default.png"),
  },
  {
    content: '1731清雍正九年扩建，纯正喇嘛庙，雍和宫下院',
    timestamp: '2018-04-03 20:46',

  },
  {
    content: '1901光绪27年初次烧毁，庙前自发形成庙会，发展为京城五大庙会之首，号称“文庙会”',
    timestamp: '2018-04-03 20:46',
  },
  {
    content: '1951 利用隆福寺的旧址修建一个容纳千户摊商以上的大型市场，即东四人民市场',
    timestamp: '2018-04-03 20:46',
  },
  {
    content: '1966-1976 受文革和唐山大地震影响，隆福寺的基址不复存在，文物残失，庙会文化于此断点',
    timestamp: '2018-04-03 20:46',
  },
  {
    content: '1988 隆福大厦建成营业，当时北京最先进的百货商场',
    timestamp: '2018-04-03 20:46',
  },
])

function enterChange(idx) {
  selectIdx.value = idx

  // activities.value = activities
}
</script>
<style lang="scss" scoped>
@media only screen and (max-width: 1536px) {
  .item_mian{
    width: 1200px !important;
  }
}
@media only screen and (max-width: 1280px) {
  .item_mian{
    width: 950px !important;
  }
}
@media only screen and (max-width: 960px) {
  .item_mian{
    width: 850px !important;
  }
}
@media only screen and (max-width: 768px) {
  .item_mian{
    width: 680px !important;
  }
}
@media only screen and (max-width: 640px) {
  .item_mian{
    width: 560px !important;
  }
}
</style>

<style lang="scss" scoped>
.history_boxs {
  font-family: PingFang SC;
  z-index: 1;
  max-width: 1920px;
  margin: 0 auto;
  .history_head {
    // border: 30px solid #ffda00;

    .img_css {
      width: 100%;
      display: flex;
    }
  }

  .history_main {
    margin-top: 9px;
    margin-bottom: 40px;

    .item_mian {
      // padding: 0 260px;
      width: 1400px;
      margin: 0 auto;

      .main_title {
        margin-top: 60px;
        text-align: center;

        .item_icon {
          width: 41px;
          height: 28px;
        }

        .item_title {
          margin-top: 10px;
          font-size: 40px;
          font-weight: 400;
          color: #8c857b;
        }
        .item_title1 {
          font-size: 40px;
          font-family: Gotham;
          font-weight: 400;
          color: #eeeeee;
          margin-bottom: 49px;
        }
      }

      .main_inner {
        display: flex;

        .item_boxs {
          width: 50%;

          .map_img {
            height: 100%;
            width: 100%;
          }

          &.text_info {
            width: 50%;
            // height: 490px;
            background: #8c857b;
            padding: 52px 40px 60px 40px;
            font-size: 24px;
            font-weight: 300;
            color: #FFFFFF;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 38px;
            font-family: PingFang SC;
          }

            .item_line {
                padding-left: 70px;

                // ::v-deep .el-timeline-item {
                //     padding-bottom: 48px;
                // }

                ::v-deep .el-timeline-item__tail {
                    height: calc(50% + 50px);
                    top: calc(50% - 50px);
                }

                .item_line_text {
                    font-size: 16rpx;
                    font-weight: 300;
                    color: #8C857B;
                }

                .select_css {
                  font-size: 20px;
                  font-weight: 400;
                }
            }
        }

        .history_map {
            width: 382px;
            height: 441px;
        }
      }
    }
  }

  ::v-deep .el-timeline-item__node--normal {
    background: url("../../assets/history/default.png"), no-repeat;
    width: 19px;
    height: 12px;
    border-radius: 0;
    left: -5px;
  }

  .select_icon {

    ::v-deep .el-timeline-item__node--normal {
      background: url("../../assets/history/select.png"), no-repeat;
      width: 22px;
      height: 14px;
      border-radius: 0;
      left: -5px;
    }
  }

  ::v-deep .el-timeline-item__tail {
    border-left: 2px solid #8C857B;
  }

  ::v-deep .el-timeline-item {
    height: 52px;
  }
}
</style>
